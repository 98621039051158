
import {
    defineComponent, Ref, watch, ref, computed,
    reactive
} from 'vue';
import textPopover from '@/components/common/text-popover/index.vue';
import '@/assets/less/index.less';
import couponDetails from '@/components/view/common/dialog/coupon-details';
import passwordDialog from '@/components/view/common/dialog/password-change';
import billingDialog from '@/components/view/common/dialog/billing-installer';
import setTimeZoneDialog from '@/components/view/common/dialog/time-change';
import customerDialog from '@/components/view/common/dialog/customer';
import accountSettingDialog from '@/components/view/common/dialog/account-setting';
import { horizontal, vertical, subvertical } from '@/components/common/nav/index';
import selectList from '@/components/common/select-list';
import goBackLogin from '@/router/back-login';
import setLang from '@/methods/setLang';
import { isCommunity, isInstaller, isOffice } from '@/methods/role';
import router, { installerBatchRouter } from '@/router';
import HttpRequest from '@/util/axios.config';
import {
    installerBaseRouter,
    communitySubRouter,
    installerSubRouter,
    communityBaseRouter
} from '@/router/data';
import {
    community, account, systemConfig
} from '@/data';
import noticeBase from '@/util/notice.base';
import changeTextByScene, { ChangeWordList } from '@/methods/setText';
import { getInitData, GetInitData } from '@/api/common/installer';
import useGetTokenStatus from '@/util/use-get-token-status';
import { insIsDisChange } from '@/data/src/account';
import {
    FormData, ComFormData, getSingleMenuList, initHeader,
    initMenu, SubSingleMenu, isShowCommunityList,
    initGrade, controlPop, batchMenu
} from './util';

export default defineComponent({
    components: {
        horizontal,
        vertical,
        textPopover,
        selectList,
        passwordDialog,
        billingDialog,
        setTimeZoneDialog,
        customerDialog,
        subvertical,
        couponDetails,
        accountSettingDialog
    },

    setup() {
        useGetTokenStatus();

        // 控制home上方下拉框的显示
        const {
            hideAllPop,
            hideOtherPop,
            controlPopVal
        } = controlPop();

        // 设置语言
        setLang();
        changeTextByScene();

        // 控制用户下拉框弹出dialog
        const {
            isShowCouponDetails,
            isShowPasswordDialog,
            isShowTimeZoneDialog,
            isShowBillingDialog,
            isShowCustomerDialog,
            accountName,
            userOperaList,
            userOperaListGrade,
            communityName
        } = initHeader();

        // 初始化菜单判断是否展示支付和订单 0展示 1不展示`
        const {
            menus,
            InstallerType,
            installerRole
        } = initMenu(community.ChargeMode.value);

        // TODO
        const {
            submain,
            submenus
        } = SubSingleMenu();

        // 初始化grade和切换Dis的方法
        const {
            grade,
            changeGradeDis
        } = initGrade();

        // 控制是否显示Role:xxx
        const isSpecial = ref(false);
        // TODO showComList有歧义
        const ShowComList = () => {
            if (isInstaller()) {
                HttpRequest.get('isspecial', {}, () => {
                    insIsDisChange.value = '1';
                    isSpecial.value = false;
                }, [() => {
                    insIsDisChange.value = '0';
                    isSpecial.value = true;
                }, false]);
            } else {
                isSpecial.value = grade.value === account.communityRole || grade.value === account.distributorRole || isOffice();
            }
        };

        // dis切换single增加身份切换的列表
        const userList = computed(
            () => (
                grade.value === account.installerRole
                && !isSpecial.value ? userOperaListGrade.value : userOperaList.value
            )
        );
        ShowComList();

        // （install 社区 办公）变化的时候触发的事件
        const changeGrade = (newAccount: string | null) => {
            HttpRequest.post('v3/web/common/manage/changeProjectIdentity', { Account: newAccount }, [(res: {
                Token: string;
                Grade: string;
            }) => {
                if (sessionStorage.getItem('token')) {
                    sessionStorage.setItem('token', res.Token);
                } else {
                    localStorage.setItem('token', res.Token);
                }
                localStorage.setItem('grade', res.Grade);
                grade.value = Number(res.Grade);
                if (res.Grade === '22') {
                    isShowCommunityList.value = false;
                    router.push(`/${installerBaseRouter}/${installerSubRouter.user}`);
                } else {
                    router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}`);
                }
            }, false]);
        };

        // （社区、办公）下拉菜单列表处理
        const CommunityList: Ref<Array<FormData>> = ref([]);
        // 设置为home页，如果activeIndex不同则是单住户页面走单住户路由
        const activeName = `${installerBaseRouter}_${installerSubRouter.home}`;

        // 批量付费和订单查询操作
        const batchRouterGroup = [
            `/${installerBaseRouter}/${installerBatchRouter.batchSubscriptions}`,
            `/${installerBaseRouter}/${installerBatchRouter.batchPayments}`,
            `/${installerBaseRouter}/${installerBatchRouter.batchPaymentInfo}`
        ];

        // 获取Project列表
        const getProjectList = () => {
            // 获取社区或者办公列表
            const requestUrl = isOffice() ? 'v3/web/office/project/getOfficeList' : 'v3/web/community/project/getAll';
            HttpRequest.get(requestUrl, {}, (res: {data: Array<ComFormData>}) => {
                CommunityList.value = [];
                communityName.value = ChangeWordList.ProjectListTitle;
                (res.data as any).row.forEach((item: any) => {
                    CommunityList.value.push({ key: item.Account, label: item.Location });
                    // 仪表盘不显示具体社区名
                    if (router.currentRoute.value.name !== activeName && !batchRouterGroup.includes(router.currentRoute.value.path)
                    && localStorage.getItem('Community') === item.Account) {
                        communityName.value = item.Location;
                    }
                });
            });
        };

        const activeIndex = ref(router.currentRoute.value.name);
        const homeUrl = ref(router.currentRoute.value.path);
        const initInstallerSet = () => {
            menus.value = getSingleMenuList(community.ChargeMode.value);
            homeUrl.value = `/${installerBaseRouter}/${installerSubRouter.user}`;
        };

        const unWatchRouter = watch(() => router.currentRoute.value, (val) => {
            activeIndex.value = val.name;
            if (batchRouterGroup.includes(val.path)) {
                menus.value = batchMenu();
                homeUrl.value = `/${installerBaseRouter}/${installerSubRouter.home}`;
            } else {
                initInstallerSet();
            }
        }, {
            immediate: true
        });

        const isShowToolBox = ref(1);
        const dowmFlieList: Ref<Array<string>> = ref([]);
        const getChargeMode = () => {
            HttpRequest.get('v3/web/common/manage/getDisChargeMode', {}, (res: {ChargeMode: string}) => {
                community.ChargeMode.value = Number(res.ChargeMode);
                if (batchRouterGroup.includes(router.currentRoute.value.path)) {
                    menus.value = batchMenu();
                    homeUrl.value = `/${installerBaseRouter}/${installerSubRouter.home}`;
                } else {
                    menus.value = getSingleMenuList(Number(res.ChargeMode));
                }
                // pay by dis关闭时显示代金券选项
                if (res.ChargeMode !== community.PayByDis) {
                    const couponItem = {
                        label: WordList.ProperAllTextCouponDetails,
                        key: 'coupon'
                    };
                    // 判断是否存在该选项，避免重复出现（新框架通过hidden控制）
                    if (userOperaList.value.findIndex((item) => item.key === 'coupon') === -1) {
                        userOperaList.value.unshift(couponItem);
                    }
                    if (userOperaListGrade.value.findIndex((item) => item.key === 'coupon') === -1) {
                        userOperaListGrade.value.unshift(couponItem);
                    }
                } else {
                    // 判断是否存在该选项再移除（新框架通过hidden控制）
                    if (userOperaList.value.findIndex((item) => item.key === 'coupon') !== -1) {
                        userOperaList.value.pop();
                    }
                    if (userOperaListGrade.value.findIndex((item) => item.key === 'coupon') !== -1) {
                        userOperaListGrade.value.pop();
                    }
                    // bug41937: 订单入口关闭
                    const paymentIdx = userOperaList.value.findIndex((item) => item.key === 'payments');
                    if (paymentIdx !== -1) {
                        userOperaList.value.splice(paymentIdx, 1);
                    }
                }
                getPwFreeConfig();
            });
            HttpRequest.get('v3/web/common/system/getMenuDownList', {}, (res: {data: Record<string, any>}) => {
                isShowToolBox.value = res.data.Show;
                res.data.List.forEach((item: string) => {
                    dowmFlieList.value.push(item);
                });
            });
        };
        if (isCommunity() || isInstaller() || isOffice()) {
            getProjectList();
        }
        getChargeMode();

        // 切换社区列表后的事件
        const changeCommunity = (key: string) => {
            hideAllPop();
            localStorage.setItem('Community', key);
            CommunityList.value.forEach((item) => {
                if (item.key === key) { communityName.value = item.label; }
            });
            changeGrade(key);
        };

        const unWatch = watch(community.isRefreshProjectList, () => {
            if (community.isFirstRefresh.value === true) {
                activeIndex.value = router.currentRoute.value.name;
                // 切换回distributor不请求
                const newGrade = localStorage.getItem('grade');
                if (newGrade === account.communityRole.toString() || newGrade === account.officeRole.toString()) {
                    getProjectList();
                }
                initInstallerSet();
                community.isFirstRefresh.value = false;
            }
        });

        // 切换成installer触发事件
        const changeInstaller = (key: string) => {
            hideAllPop();
            localStorage.setItem('grade', key);
            grade.value = Number(key);
            if (key === '22') {
                installerRole.label = WordList.TabelUserInHtmlRoleInstall;
                isShowCommunityList.value = false;
                activeIndex.value = `${installerBaseRouter}_${installerSubRouter.user}`;
                initInstallerSet();
                changeGrade(localStorage.getItem('peraccount'));
                localStorage.setItem('InstallerLoginType', '0');
            } else {
                communityName.value = ChangeWordList.ProjectListTitle;
                installerRole.label = WordList.ProperAllTextProjectManager;
                isShowCommunityList.value = true;
                activeIndex.value = activeName;
                getProjectList();
                initInstallerSet();
                router.push(`/${installerBaseRouter}/${installerSubRouter.home}`);
                localStorage.setItem('InstallerLoginType', '1');
            }
        };

        // 判断单住户、社区高亮状态
        const activeRole = ref(0);
        const loginAccount: string | null = (sessionStorage.getItem('userName')
            ? sessionStorage.getItem('userName') : localStorage.getItem('userName')) as string;
        const isFirstLogin = () => {
            if (localStorage.getItem('initialization') === '0') {
                noticeBase.messageBox('confirm', WordList.ProperAllTextFirstLoginTips.format(loginAccount),
                    WordList.LoginTips, 'warning')(() => {
                    isShowPasswordDialog.value = true;
                });
            }
            localStorage.setItem('initialization', '1');
        };
        isFirstLogin();
        watch(isShowCommunityList, () => {
            if (isShowCommunityList.value) {
                activeRole.value = account.communityRole;
            } else {
                activeRole.value = account.installerRole;
            }
        }, {
            immediate: true
        });

        /**
         * 6.5.2dis免密登录需求
         * ins控制免密登录是否开启
         */
        // 控制是否显示account setting弹窗
        const isShowAccountSettingDialog = ref(false);
        // 获取ins是否开启免密登录
        const pwFreeConfig = reactive({
            IsPwdFreeLogin: 0
        });
        // 判断是否为dis登录
        const isDisLogin = ref(false);
        function getPwFreeConfig() {
            if (sessionStorage.getItem('token')) {
                isDisLogin.value = true;
            } else {
                getInitData({}, (res: GetInitData) => {
                    // dis第一次打开开关后,ins第一次登录弹窗提示
                    if (res.IsPwdFreeLoginNotice === 1) {
                        noticeBase.messageBox(
                            'alert',
                            WordList.FirstAccessAccountTips,
                            WordList.Notice,
                            'warning'
                        )(() => false);
                    }
                    // dis开启免密登录，ins新增选项Account Setting
                    if (res.IsShowPermissionGranted === 1) {
                        // 判断是否存在该选项，避免重复出现（新框架通过hidden控制）
                        if (userOperaList.value.findIndex((item) => item.key === 'accountSetting') === -1) {
                            userOperaList.value.push({
                                label: WordList.AccountSetting,
                                key: 'accountSetting'
                            });
                        }
                    }
                    pwFreeConfig.IsPwdFreeLogin = res.IsPwdFreeLogin;
                });
            }
        }

        // 用户下拉菜单展示
        const chooseOpera = (key: string) => {
            hideAllPop();
            switch (key) {
                case 'coupon':
                    isShowCouponDetails.value = true;
                    break;
                case 'password':
                    isShowPasswordDialog.value = true;
                    break;
                case 'timezone':
                    isShowTimeZoneDialog.value = true;
                    break;
                case 'bill':
                    isShowBillingDialog.value = true;
                    break;
                case 'customer':
                    isShowCustomerDialog.value = true;
                    break;
                case 'identity':
                    changeGradeDis();
                    break;
                case 'payments': {
                    localStorage.setItem('Community', '');
                    const role = localStorage.getItem('grade');
                    let type = '';
                    // 单住户点击切换成社区
                    if (role === account.installerRole.toString()) {
                        changeInstaller(account.communityRole.toString());
                        type = 'community';
                    } else {
                        type = role === account.communityRole.toString() ? 'community' : 'office';
                    }
                    router.push(`/${installerBaseRouter}/${installerBatchRouter.batchPayments}?type=${type}`);
                    break;
                }
                case 'accountSetting':
                    isShowAccountSettingDialog.value = true;
                    break;
                default:
                    break;
            }
        };

        systemConfig.getSysConfig((res) => {
            systemConfig.sysConfig.EnableSmartHome = res.EnableSmartHome;
        });

        return {
            menus,
            accountName,
            communityName,
            isShowCouponDetails,
            isShowCommunityList,
            isShowPasswordDialog,
            isShowTimeZoneDialog,
            isShowBillingDialog,
            isShowCustomerDialog,
            InstallerType,
            goBackLogin,
            changeGrade,
            changeInstaller,
            installerRole,
            userOperaList,
            userOperaListGrade,
            chooseOpera,
            activeIndex,
            activeName,
            CommunityList,
            changeCommunity,
            homeUrl,
            ShowComList,
            isSpecial,
            submain,
            submenus,
            dowmFlieList,
            SubSingleMenu,
            isShowToolBox,
            grade,
            userList,
            activeRole,
            loginAccount,
            isFirstLogin,
            controlPopVal,
            hideOtherPop,
            unWatch,
            unWatchRouter,
            isShowAccountSettingDialog,
            getPwFreeConfig,
            pwFreeConfig,
            isDisLogin
        };
    },
    beforeUnmount() {
        if (this.unWatch) {
            this.unWatch();
        }
        if (this.unWatchRouter) {
            this.unWatchRouter();
        }
    }
});

